import React from 'react'
import { Link, graphql, useStaticQuery, } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allStrapiBlog {
      nodes {
        title
        slug
        date(formatString: "MMMM Do, YYYY")
        image {
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
      }
    }
  }
`

const BlogCards = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiBlog: { nodes: blogs },
    } = data
    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                    {blogs.map((blog) => {
                        const image = getImage(blog.image.localFile)
                        return (
                            <div className="col-lg-4 col-md-6">
                                <Link to={`/blog/${blog.slug}`}>
                                    <div className="single-blog-post bg-fffbf5">
                                        <div className="post-image">
                                            <GatsbyImage image={image}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                            />
                                        </div>

                                        <div className="post-content">
                                            <ul className="post-meta d-flex justify-content-between align-items-center">
                                                <li>
                                                    <i className='flaticon-calendar'></i> {blog.date}
                                                </li>
                                            </ul>
                                            <h3>
                                                {blog.title}
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div >
        </div >
    )
}

export default BlogCards