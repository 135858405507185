import React from 'react'
import shape2 from '../../assets/images/shape/shape2.png'
import shape3 from '../../assets/images/shape/shape3.png'

const PageBanner = ({pageTitle}) => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                </div>
            </div>

            {/* <div className="shape-img1">
                <img src={shape1} alt="banner" />
            </div> */}
            <div className="shape-img2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="shape-img3">
                <img src={shape3} alt="banner" />
            </div>
        </div>
    )
}

export default PageBanner;